import { handlerRangeRequest } from '@filters/filterFactories/inventoryAuditDetailsFilters';
import {
  FilterFunction,
  ProductInventoryItemFilterInput,
} from '@filters/types';

export const createProductInventoryItemReportFilter: FilterFunction<
  ProductInventoryItemFilterInput
> = ({
  productId,
  productType,
  locationIds,
  sublocation,
  age,
} = {}): InvtItmRecordFiltersInput => {
  const productFilter =
    productId || productType
      ? {
          product: {
            id: {
              eq: productId,
            },
            productType: {
              id: {
                eq: productType,
              },
            },
          },
        }
      : {};

  const businessLocationFilter =
    locationIds?.length !== 0 || sublocation
      ? {
          businessLocation: {
            id: {
              in: locationIds,
            },
          },
          sublocation: {
            id: {
              eq: sublocation,
            },
          },
        }
      : {};

  let ageFilter = {};

  if (age) {
    const [minAge, maxAge] = age.split('-').map(Number);
    ageFilter = {
      ...handlerRangeRequest({
        key: 'age',
        minValue: minAge,
        maxValue: maxAge,
      }),
    };
  }

  return {
    productInventoryItem: { ...productFilter, ...businessLocationFilter },
    ...ageFilter,
  };
};
