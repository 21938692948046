import { EmployeeStatus } from '@components/hr/employees/employeeInputs/EmployeeStatusSelect';
import dayjs, { Dayjs } from 'dayjs';

import {
  createActivityDueDateFilter,
  createActivityOwnerFilter,
  createActivityTypeFilter,
  createCompanyFilter,
  createContactFilter,
  createLeadOwnerFilter,
  createLeadSourceFilter,
  createLeadStageFilter,
} from '@filters/filterFactories/crmFilters';
import {
  createEmployeeStatusFilter,
  createEmployeeTeamFilter,
} from '@filters/filterFactories/hrFilters';
import {
  createTransferOrdersCreatedAtFilter,
  createTransferOrdersEmployeeFilters,
  createTransferOrdersLocationFromFilters,
  createTransferOrdersLocationToFilters,
  createTransferOrdersProductFilters,
  createTransferOrdersSubLocationFromFilters,
  createTransferOrdersSubLocationToFilters,
  createTransferOrdersTransferDateFilter,
} from '@filters/filterFactories/transferOrdersFilters';
import {
  AdditionalFilterFunction,
  CrmFilters,
  CrmSingleEntityFiltersInput,
} from '@filters/types';

import { createChartFilter } from '@components/accounting/transactions/utils';
import {
  createCampaignOwnerFilter,
  createEnrolledContactsFilter,
} from '@filters/filterFactories/campaignFilters';
import {
  createFormCreatedAtFilter,
  createFormNameFilter,
  createFormStatusOrCustomerNameFilter,
  createSendToFilter,
} from '@filters/filterFactories/contractFormsFilters';
import {
  createContractCreatedAtFilter,
  createNameContractFilter,
  createStatusContractFilter,
  customerContractFilter,
} from '@filters/filterFactories/contractsFilter';
import {
  createAllContactsFilter,
  createStageFilter,
} from '@filters/filterFactories/dealsFilters';
import {
  createInventoryAdjustmentCreatedAtFilter,
  createInventoryAdjustmentDateOfAdjustmentFilter,
  createInventoryAdjustmentEmployeeFilters,
  createInventoryAdjustmentLocationFilters,
  createInventoryAdjustmentProductFilters,
  createInventoryAdjustmentSubLocationFilters,
} from '@filters/filterFactories/inventoryAdjustmentFilters';
import {
  createInventoryAuditDetailsFilters,
  createInventoryAuditDetailsInventoryQtyFilters,
  createInventoryAuditDetailsScannedQtyFilters,
} from '@filters/filterFactories/inventoryAuditDetailsFilters';
import {
  createInventoryAuditBusinessLocationFilters,
  createInventoryAuditCreatedAtFilter,
  createInventoryAuditDateOfAuditFilter,
  createInventoryAuditSublocationFilters,
} from '@filters/filterFactories/inventoryAuditFilters';
import {
  createInventoryInvoiceCreatedAtFilter,
  createInventoryInvoiceCustomerFilter,
} from '@filters/filterFactories/inventotyInvoiveFilters';
import {
  createBusinessLocationFilter,
  createMaintainedByFilter,
  createResourceFilter,
} from '@filters/filterFactories/maintenanceFilters';
import { createCommonFilters } from '@filters/filterFactories/marketingReportFilters';
import {
  createBusinessLocationProductFilter,
  createInventoryProductCreatedAtFilter,
  createInventoryProductItemFilter,
} from '@filters/filterFactories/productInventoryFilter';
import {
  createCustomerFilter,
  createReturnDateFilter,
  createReturnMethodFilter,
  createReturnOrderTypeFilter,
  createReturnTypeFilter,
} from '@filters/filterFactories/returnFilters';
import {
  createApprovalMethodFilter,
  createAssigneesFilter,
  createCustomersFilters,
  createForCompanyFilter,
  createPriorityTypeFilter,
  createTaskLocationFilter,
  createTaskOrderFilter,
  createTaskStageFilter,
  createTaskTypeFilter,
  createTasksDueDateFilter,
} from '@filters/filterFactories/tasksFilters';

import {
  createMemoReportExpiryDateFilter,
  createMemoReportLocationFilter,
  createMemoReportProductFilter,
  createMemoReportReceiveDateFilter,
  createMemoReportVendorFilter,
} from '@filters/filterFactories/memoFilters';

import {
  createAppraisalCreatedAtFilter,
  createEmployeeAppraisalFilter,
  createNameAppraisalFilter,
} from '@filters/filterFactories/appraisalFilter';
import {
  createEstimateContactFilter,
  createEstimateCreatedAtFilter,
  createEstimateIdFilter,
} from '@filters/filterFactories/estimateFilters';
import {
  createMemoOutReportOrderFilter,
  createMemoOutReportProductFilter,
} from '@filters/filterFactories/memoOutFilters';
import { createProductInventoryItemReportFilter } from '@filters/filterFactories/productInventoryItemFilter';
import {
  createPurchaseRequestIdFilter,
  createPurchaseRequestVendorFilter,
} from '@filters/filterFactories/purchaseRequestFilters';
import {
  createSalesItemReportBusinessLocationFilter,
  createSalesItemReportOrderFilter,
  createSalesItemReportSalesFilter,
  createSalesItemReportShopItemFilter,
  createSalesItemReportTypeFilter,
} from '@filters/filterFactories/salesItemFilters';
import { transformToDateFilterInput } from '@helpers/filters';
import { CustomerObject } from 'src/components/ui/form/ContactsANDCompaniesSelect';
import {
  createDealTransactionCreatedAtFilter,
  createDealTransactionCustomersFilters,
  createDealTransactionDueDateFilter,
  createSalesOrderFilter,
  createStatusFilter,
} from './dealTransactionsFilters';
import {
  createBusinessLocationOrderFilter,
  createOrderIdFilter,
  createOrderTypeFilter,
  createOrdersPeriodFilter,
  createSalesFilter,
} from './ordersFilters';
import {
  createTaxesOrderFilter,
  createTaxesReportCreatedAtFilter,
} from './taxesFilters';

export const getLeadFilters: AdditionalFilterFunction<{
  leadSource?: string[];
  leadStage?: string[];
  leadOwner?: string[];
}> = (values): LeadFiltersInput =>
  ({
    ...createLeadSourceFilter(values.leadSource),
    ...createLeadStageFilter(values.leadStage),
    ...createLeadOwnerFilter(values.leadOwner),
  }) as LeadFiltersInput;

export const getContactFilters: AdditionalFilterFunction<{
  leadSource?: string[];
  leadOwner?: string[];
  company?: string[];
  contact?: string;
}> = (values): CrmSingleEntityFiltersInput =>
  ({
    ...createLeadSourceFilter(values.leadSource),
    ...createLeadOwnerFilter(values.leadOwner),
    ...createCompanyFilter(values.company),
    ...createContactFilter(values.contact),
  }) as CrmFilters;

export const getCompanyFilters: AdditionalFilterFunction<{
  leadOwner?: string[];
}> = (values): CompanyFiltersInput =>
  ({
    ...createLeadOwnerFilter(values.leadOwner),
  }) as CompanyFiltersInput;

export const getActivityFilters: AdditionalFilterFunction<{
  type?: string[];
  due_date?: Date;
  owner?: string;
}> = (values): ActivityFiltersInput =>
  ({
    ...createActivityTypeFilter(values?.type),
    ...createActivityOwnerFilter(values?.owner),
    ...createActivityDueDateFilter(values?.due_date),
  }) as ActivityFiltersInput;

export const getEmployeeFilters: AdditionalFilterFunction<{
  status?: EmployeeStatus;
  team?: string;
}> = (values): UsersPermissionsUserFiltersInput =>
  ({
    ...createEmployeeStatusFilter(values.status),
    ...createEmployeeTeamFilter(values.team),
  }) as UsersPermissionsUserFiltersInput;

export const getTasksFilters: AdditionalFilterFunction<{
  priority?: string;
  assignees?: string[];
  dueDate?: [Dayjs | null, Dayjs | null] | null | undefined;
  approvalMethod?: string;
  taskType?: string;
  taskStage?: string;
  orderId?: string;
  taskLocation?: string;
  forCompany?: string;
  customer?: {
    contact: string | null;
    lead: string | null;
    company: string | null;
  };
}> = (values): TaskFiltersInput => {
  return {
    ...createPriorityTypeFilter(values.priority),
    ...createAssigneesFilter(values.assignees),
    ...createTasksDueDateFilter(values?.dueDate),
    ...createCustomersFilters(values),
    ...createApprovalMethodFilter(values?.approvalMethod),
    ...createTaskTypeFilter(values?.taskType),
    ...createTaskStageFilter(values?.taskStage),
    ...createTaskOrderFilter(values?.orderId),
    ...createForCompanyFilter(values?.forCompany),
    ...createTaskLocationFilter(values.taskLocation),
  } as TaskFiltersInput;
};

export const getDealTransactionsFilters: AdditionalFilterFunction<{
  status?: string;
  dueDate?: Date;
  createdAt?: [null | dayjs.Dayjs, null | dayjs.Dayjs] | null;
  chart?: string;
  sellingOrder?: string;
  allContacts?: { contact?: string; company?: string };
}> = (values) => {
  return {
    ...createStatusFilter(values?.status),
    ...createDealTransactionDueDateFilter(values?.dueDate),
    ...createDealTransactionCreatedAtFilter(values?.createdAt),
    ...createChartFilter(values),
    ...createSalesOrderFilter(values?.sellingOrder),
    ...createDealTransactionCustomersFilters(values?.allContacts),
  } as DealTransactionFiltersInput;
};

export const getTransferOrdersFilters: AdditionalFilterFunction<{
  locationFrom?: string;
  sublocationFrom?: string;
  locationTo?: string;
  sublocationTo?: string;
  product?: string;
  employee?: string;
  orderDate?: Date;
  createdAt?: Date;
}> = (values): TransferOrderFiltersInput =>
  ({
    ...createTransferOrdersLocationFromFilters(values.locationFrom),
    ...createTransferOrdersSubLocationFromFilters(values.sublocationFrom),
    ...createTransferOrdersLocationToFilters(values.locationTo),
    ...createTransferOrdersSubLocationToFilters(values.sublocationTo),
    ...createTransferOrdersProductFilters(values.product),
    ...createTransferOrdersEmployeeFilters(values.employee),
    ...createTransferOrdersTransferDateFilter(values.orderDate),
    ...createTransferOrdersCreatedAtFilter(values.createdAt),
  }) as TransferOrderFiltersInput;

export const getInventoryAdjustmentFilters: AdditionalFilterFunction<{
  location?: string;
  sublocation?: string;
  product?: string;
  employee?: string;
  adjustmentDate?: Date;
  createdAt?: Date;
}> = (values): InventoryAdjustmentFiltersInput =>
  ({
    ...createInventoryAdjustmentDateOfAdjustmentFilter(values.adjustmentDate),
    ...createInventoryAdjustmentProductFilters(values.product),
    ...createInventoryAdjustmentCreatedAtFilter(values.createdAt),
    ...createInventoryAdjustmentLocationFilters(values.location),
    ...createInventoryAdjustmentSubLocationFilters(values.sublocation),
    ...createInventoryAdjustmentEmployeeFilters(values.employee),
  }) as InventoryAdjustmentFiltersInput;

export const getInventoryAuditFilters: AdditionalFilterFunction<{
  businessLocation?: string;
  sublocation?: string;
  auditDate?: Date;
  createdAt?: Date;
}> = (values): InventoryAuditFiltersInput =>
  ({
    ...createInventoryAuditDateOfAuditFilter(values.auditDate),
    ...createInventoryAuditCreatedAtFilter(values.createdAt),
    ...createInventoryAuditBusinessLocationFilters(values.businessLocation),
    ...createInventoryAuditSublocationFilters(values.sublocation),
  }) as InventoryAuditFiltersInput;

export const getInventoryAuditDetailsFilters: AdditionalFilterFunction<{
  name?: string;
  barcode?: string;
  vendor?: string;
  price?: string;
  quantity?: string;
  scannedQty?: string;
  scanned?: string;
}> = (values): InventoryAuditItemFiltersInput => {
  return {
    ...createInventoryAuditDetailsFilters({
      name: values.name,
      barcode: values.barcode,
      vendor: values.vendor,
      price: values.price,
    }),
    ...createInventoryAuditDetailsInventoryQtyFilters(values.quantity),
    ...createInventoryAuditDetailsScannedQtyFilters(values.scannedQty),
  } as InventoryAuditItemFiltersInput;
};

export const getDealsFilters: AdditionalFilterFunction<{
  startDate?: [null | dayjs.Dayjs, null | dayjs.Dayjs] | null;
  stage?: string;
  allContacts?: { contact?: string; lead?: string; company?: string };
}> = (values) => {
  return {
    startDate: transformToDateFilterInput(values?.startDate),
    ...createStageFilter(values?.stage),
    ...createAllContactsFilter(values?.allContacts),
  } as DealFiltersInput;
};

export const getMaintenanceFilters: AdditionalFilterFunction<{
  businessLocations?: string[];
  resources?: string[];
  maintainedBy?: string[];
}> = (values): MaintenanceFiltersInput =>
  ({
    resourceInventoryItem: {
      ...createBusinessLocationFilter(values.businessLocations),
      ...createResourceFilter(values.resources),
    },
    ...createMaintainedByFilter(values.maintainedBy),
  }) as MaintenanceFiltersInput;

export const getOrdersFilters: AdditionalFilterFunction<{
  dueDate?: [null | dayjs.Dayjs, null | dayjs.Dayjs] | null;
  orderId?: string;
  locationIds?: string[];
  sales?: string;
  type?: string;
}> = (values) =>
  ({
    dueDate: transformToDateFilterInput(values?.dueDate),
    ...createOrderIdFilter(values?.orderId),
    ...createBusinessLocationOrderFilter(values?.locationIds),
    ...createSalesFilter(values.sales),
    ...createOrderTypeFilter(values.type),
  }) as OrderFiltersInput;

export const getOrdersManagementFilters: AdditionalFilterFunction<{
  createdAt?: [Dayjs | null, Dayjs | null] | undefined;
  orderId?: string;
  customer?: CustomerObject;
}> = (values) =>
  ({
    ...createOrdersPeriodFilter(values?.createdAt),
    ...createOrderIdFilter(values?.orderId),
    ...createCustomerFilter(values.customer),
  }) as OrderFiltersInput;

export const getSalesItemFilters: AdditionalFilterFunction<{
  orderId?: string;
  type?: string;
  productId?: string;
  productType?: string;
  compositeProductId?: string;
  serviceId?: string;
  membershipId?: string;
  classId?: string;
  locationIds?: string[];
  sublocation?: string;
  sales?: string;
  dueDate?: [null | dayjs.Dayjs, null | dayjs.Dayjs] | null;
}> = (values) =>
  ({
    dueDate: transformToDateFilterInput(values?.dueDate),
    ...createSalesItemReportOrderFilter(values?.orderId),
    ...createSalesItemReportTypeFilter(values.type),
    ...createSalesItemReportShopItemFilter({
      productId: values?.productId,
      productType: values?.productType,
      compositeProductId: values?.compositeProductId,
      serviceId: values?.serviceId,
      membershipId: values?.membershipId,
      classId: values?.classId,
    }),
    ...createSalesItemReportBusinessLocationFilter({
      locationIds: values?.locationIds,
      sublocation: values?.sublocation,
    }),
    ...createSalesItemReportSalesFilter(values.sales),
  }) as OrderFiltersInput;

export const getCampaignFilters: AdditionalFilterFunction<{
  campaignOwner?: string[];
  contacts?: string[];
}> = (values): CampaignFiltersInput =>
  ({
    ...createCampaignOwnerFilter(values?.campaignOwner),
    ...createEnrolledContactsFilter(values?.contacts),
  }) as CampaignFiltersInput;

export const getReturnFilter: AdditionalFilterFunction<{
  customer?: CustomerObject;
  returnDate?: [null | dayjs.Dayjs, null | dayjs.Dayjs];
  type?: string;
  returnMethod?: string;
  orderType?: string;
}> = (values): ReturnFiltersInput =>
  ({
    ...createReturnDateFilter(values.returnDate),
    ...createReturnTypeFilter(values.type),
    ...createReturnMethodFilter(values.returnMethod),
    ...createCustomerFilter(values.customer),
    ...createReturnOrderTypeFilter(values.orderType),
  }) as ReturnFiltersInput;

export const getInventoryInvoiceFilter: AdditionalFilterFunction<{
  customer?: CustomerObject;
  createdAt?: Date;
}> = (values): InvoiceFiltersInput =>
  ({
    ...createInventoryInvoiceCustomerFilter(values.customer),
    ...createInventoryInvoiceCreatedAtFilter(values.createdAt),
  }) as InvoiceFiltersInput;

export const getContractFilters: AdditionalFilterFunction<{
  name?: string;
  status?: string;
  contactId?: string;
  createdAt?: Date;
}> = (values): ContractFiltersInput =>
  ({
    ...createNameContractFilter(values.name),
    ...createStatusContractFilter(values.status),
    ...createContractCreatedAtFilter(values.createdAt),
    ...customerContractFilter(values.contactId),
  }) as ContractFiltersInput;

export const getAppraisalFilters: AdditionalFilterFunction<{
  name?: string;
  employee?: string[];
  createdAt?: Date;
}> = (values): AppraisalFiltersInput =>
  ({
    ...createNameAppraisalFilter(values.name),
    ...createEmployeeAppraisalFilter(values.employee),
    ...createAppraisalCreatedAtFilter(values.createdAt),
  }) as AppraisalFiltersInput;

export const getContractFormsFilters: AdditionalFilterFunction<{
  name?: string;
  sendTo?: string;
  submitted?: boolean;
  customerName?: string;
  createdAt?: Date;
}> = (values): FormFiltersInput =>
  ({
    ...createFormNameFilter(values.name),
    ...createSendToFilter(values.sendTo),
    ...createFormCreatedAtFilter(values.createdAt),
    ...createFormStatusOrCustomerNameFilter(
      values.submitted,
      values.customerName,
    ),
  }) as FormFiltersInput;

export const getProductInventoryFilters: AdditionalFilterFunction<{
  productId?: string;
  productType?: string;
  createdAt?: Date;
  locationIds?: string[];
  sublocation?: string;
}> = (values): ProductFiltersInput =>
  ({
    ...createInventoryProductItemFilter({
      productId: values?.productId,
      productType: values?.productType,
    }),
    ...createInventoryProductCreatedAtFilter(values?.createdAt),
    ...createBusinessLocationProductFilter({
      locationIds: values?.locationIds,
      sublocation: values?.sublocation,
    }),
  }) as ProductFiltersInput;

export const getProductInventoryItemFilters: AdditionalFilterFunction<{
  productId?: string;
  productType?: string;
  locationIds?: string[];
  sublocation?: string;
  age?: string;
}> = (values): InvtItmRecordFiltersInput =>
  ({
    ...createProductInventoryItemReportFilter({
      productId: values?.productId,
      productType: values?.productType,
      locationIds: values?.locationIds,
      sublocation: values?.sublocation,
      age: values?.age,
    }),
  }) as InvtItmRecordFiltersInput;

export const getMemoFilters: AdditionalFilterFunction<{
  productId?: string;
  locationId?: string;
  vendorId?: string;
  receiveDate?: [null | dayjs.Dayjs, null | dayjs.Dayjs] | null;
  expiryDate?: [null | dayjs.Dayjs, null | dayjs.Dayjs] | null;
}> = (values) => {
  return {
    ...createMemoReportProductFilter(values?.productId),
    ...createMemoReportLocationFilter(values?.locationId),
    ...createMemoReportVendorFilter(values?.vendorId),
    ...createMemoReportReceiveDateFilter(values?.receiveDate),
    ...createMemoReportExpiryDateFilter(values?.expiryDate),
  } as ProductInventoryItemEventFiltersInput;
};

export const getMemoOutFilters: AdditionalFilterFunction<{
  productId?: string;
  contactId?: string;
  companyId?: string;
  sentDate?: [null | dayjs.Dayjs, null | dayjs.Dayjs] | null;
  expiryDate?: [null | dayjs.Dayjs, null | dayjs.Dayjs] | null;
}> = (values) => {
  return {
    ...createMemoOutReportProductFilter(values?.productId),
    ...createMemoOutReportOrderFilter({
      contactId: values?.contactId,
      companyId: values?.companyId,
      sentDate: values?.sentDate,
      expiryDate: values?.expiryDate,
    }),
  } as ProductOrderItemFiltersInput;
};

export const getTaxesFilters: AdditionalFilterFunction<{
  orderId?: string;
  createdAt?: Date;
}> = (values) => {
  return {
    ...createTaxesOrderFilter(values?.orderId),
    ...createTaxesReportCreatedAtFilter(values?.createdAt),
  } as TaxReportFiltersInput;
};

export const getMarketingReportFilters: AdditionalFilterFunction<{
  customer?: {
    contact: string | null;
    lead: string | null;
  };
  campaignId?: string;
}> = (values) => {
  return {
    ...createCommonFilters(values),
  } as MarketingCustomersReportFiltersInput;
};

export const getPurchaseRequestFilter: AdditionalFilterFunction<{
  vendor?: string;
  requestId?: string;
}> = (values): InvoiceFiltersInput =>
  ({
    ...createPurchaseRequestVendorFilter(values.vendor),
    ...createPurchaseRequestIdFilter(values.requestId),
  }) as InvoiceFiltersInput;

export const getEstimateFilter: AdditionalFilterFunction<{
  customer?: CustomerObject;
  estimateId?: string;
  createdAt?: Date;
}> = (values): InvoiceFiltersInput =>
  ({
    ...createEstimateContactFilter(values.customer),
    ...createEstimateIdFilter(values.estimateId),
    ...createEstimateCreatedAtFilter(values.createdAt),
  }) as InvoiceFiltersInput;
