import { FC, useState } from 'react';

import { InputNumber, InputNumberProps } from 'antd';

import { formatNumber } from '@helpers/formatter';
import classNames from 'classnames';
import styles from './currencyInput.module.scss';

interface Props extends InputNumberProps<number> {
  skipFormatting?: boolean;
  unchangeable?: boolean;
}
export const CurrencyInput: FC<Props> = (props) => {
  const {
    placeholder,
    prefix = '$',
    skipFormatting,
    unchangeable = false,
    ...restProps
  } = props;

  const [isTyping, setIsTyping] = useState(false);

  const toggleIsTyping = () => {
    setIsTyping((prev) => !prev);
  };

  const formatValue: InputNumberProps<number>['formatter'] = (value, info) => {
    if (isTyping) {
      const numberForParsing = info.input.split(',').join('');

      return formatNumber(Number(numberForParsing), {
        minimumFractionDigits: 0,
      });
    }

    return formatNumber(value);
  };

  return (
    <div
      className={classNames(undefined, {
        [styles.CurrencyInputWrapper]: unchangeable,
      })}
    >
      <InputNumber<number>
        {...restProps}
        controls={false}
        placeholder={placeholder ?? '0.00'}
        min={0}
        prefix={prefix}
        formatter={!skipFormatting ? formatValue : undefined}
        onFocus={toggleIsTyping}
        onBlur={toggleIsTyping}
        style={{ width: '100%' }}
      />
    </div>
  );
};
